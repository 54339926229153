export default function donationRibbon() {
  document.querySelectorAll('.story-donation-ribbon-container').forEach((ribbon) => {
    let ribbonSpacer = ribbon.nextElementSibling;

    if (ribbonSpacer.nextElementSibling != null && !ribbonSpacer.nextElementSibling.classList.contains('subscibe-news-form')) {
      calcDimensions(ribbon, ribbonSpacer);
    }

    if (ribbonSpacer.nextElementSibling == null) {
      calcDimensions(ribbon, ribbonSpacer);
    }
  });

  let donateRibbonComponent = document.querySelector('.story-donation-ribbon-container'),
    sideBarWidget = document.querySelector('.sticky.top-24');

  if (donateRibbonComponent != null) {
    if(donateRibbonComponent.previousElementSibling != null && donateRibbonComponent.previousElementSibling.classList.contains('related-container')) {
      donateRibbonComponent = document.querySelector('.related-container');
    }
  }
  


  if (donateRibbonComponent != undefined && sideBarWidget != undefined && !sideBarWidget.classList.contains('top-donation-widget')) {
    if (window.innerWidth > 768) {
      let donateRibbonTopBorderPos = donateRibbonComponent.offsetTop - donateRibbonComponent.offsetHeight;

      if (sideBarWidget.offsetTop >= donateRibbonTopBorderPos) {
        sideBarWidget.style.position = 'absolute';
        sideBarWidget.scrollBy({ top: donateRibbonTopBorderPos, behavior: 'smooth' })
      }

      if (donateRibbonComponent.getBoundingClientRect().top > sideBarWidget.offsetHeight + 100) {
        sideBarWidget.style.position = 'absolute';
        sideBarWidget.style.top = `${window.pageYOffset + 90}px`;
      }

      document.addEventListener('scroll', () => {
        if (sideBarWidget.offsetTop >= donateRibbonTopBorderPos) {
          sideBarWidget.scrollBy({ top: (donateRibbonTopBorderPos - 50), behavior: 'smooth' })
        }

        if (donateRibbonComponent.getBoundingClientRect().top > sideBarWidget.offsetHeight + 100) {
          sideBarWidget.style.position = 'absolute';
          sideBarWidget.style.top = `${window.pageYOffset + 90}px`;
        }
      }, true);
    }
  }

}

function calcDimensions(ribbon, spacer) {
  ribbon.classList.add('donation-shortcode-styles')

  ribbon.style.width = `${document.body.offsetWidth}px`;
  spacer.style.height = `${ribbon.offsetHeight}px`;

  window.addEventListener('load', () => {
    ribbon.style.width = `${document.body.offsetWidth}px`;
    spacer.style.height = `${ribbon.offsetHeight}px`;
  });

  window.addEventListener('resize', () => {
    ribbon.style.width = `${document.body.offsetWidth}px`;
    spacer.style.height = `${ribbon.offsetHeight}px`;
  });
}