<template>
  <!--
    Paypal documentation

    https://developer.paypal.com/api/nvp-soap/paypal-payments-standard/integration-guide/Appx-websitestandard-htmlvariables/#link-paymenttransactionvariables
  -->
  <div>
    <div>
      <label class="container-label text-base sm:text-lg text-black">
        <input type="checkbox" v-model="emailConsentPP" />
        <span class="checkmark"></span>
        <static-string> I consent to be contacted by Liberties via email.</static-string>
      </label>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 mb-6 items-center gap-1">
      <form
        class="flex items-stretch w-full flex-col"
        :action="PAYPAL_FORM_ENV == 'dev' ? 'https://www.sandbox.paypal.com/cgi-bin/webscr' : 'https://www.paypal.com/cgi-bin/webscr'"
        method="post"
        target="_top"
        @submit="handleSubmit"
      >
        
        <input v-if="PAYPAL_FORM_ENV == 'dev'" type="hidden" name="business" value="sb-thmpk27596115@business.example.com" />
        <input v-else type="hidden" name="business" :value="paypalMerchantId" />
        <input type="hidden" name="cmd" value="_donations" />

        <!-- Amount should be in the following format: '10.00' -->
        <input type="hidden" name="amount" :value="amountFormattedForInput" />
        <input type="hidden" name="currency_code" value="EUR" />

        <!-- This enables the "Make your donation recurring" checkbox -->
        <input type="hidden" name="no_recurring" value="0" />


        <input type="hidden" name="return" :value="paypalSuccessPageUrl" />
        <!-- The buyer's browser is redirected to the return URL by using the POST method, and all payment variables are included. -->
        <input type="hidden" name="rm" value="2" />
        <input type="hidden" name="cancel_return" :value="paypalCancelPageUrl" />
        <input type="hidden" name="notify_url" :value="ipnWebhook"> 

        <input type="hidden" name="email_consent" :value="emailConsentValue" />
      
        <button
          id="donate-page-btn-pp"
          type="submit"
          class="donation-buttons -payment bg-orange text-white w-full"
        >
          <static-string>Pay With PayPal</static-string>
        </button>

        <img
          border="0"
          src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
          width="1"
          height="1"
        />
    
      </form>
      <span class="text-left pl-5 align-to-pp" >
        <static-string>We will redirect you to PayPal’s secure payment page.</static-string>
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  inject: ['paypalMerchantId', 'paypalSuccessPageUrl', 'paypalCancelPageUrl', 'ipnWebhook'],

  props: {
    donation: { required: true, type: Object }
  },


  data() {
    return {
      emailConsentPP: false,
      PAYPAL_FORM_ENV: process.env.PAYPAL_FORM_ENV || 'prod'
    };
  },

  methods: {
    async handleSubmit() {
      const payload = {
        consent: this.emailConsentPP,
      };

      console.log(this.emailConsentPP)
      if (this.emailConsentPP == true) {
        try {
          const response = await axios.post('/en/donation-pp-data', payload);

          if (response.data.success) {
            console.error('Request success!');
            const form = this.$el;
            form.submit();
          } else {
            console.error('Request failed:', response.data.message);
          }
        } catch (error) {
          console.error('Error making request:', error);
        }
      }
    }
  },

  computed: {
    amountFormattedForInput() {
      return `${this.donation.amount}.00`;
    },

    amount() {
      return this.donation.amount;
    },

    isRecurring() {
      return this.donation.interval === 'monthly';
    },

    emailConsentValue() {
      return this.emailConsentPP ? 'true' : 'false';
    }
  }
};
</script>

<style lang="scss" scoped>
.donation-buttons {
  min-height: 4rem;
}
</style>
